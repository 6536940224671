@import '../../styles/mixins';

html {
  color: $base-font-color;
  font-family: 'go-light' !important;
  font-weight: 400;
  // font-family: $base-font-family;
  font-size: 100%;
  line-height: $base-line-height;
}

h1,
h2,
h3 {
  // font-family: $heading-font-family;
  font-family: 'go-light' !important;
  font-size: modular-scale(1);
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}

h4,
h5,
h6 {
  // font-family: $heading-font-family;
  font-family: 'go-light' !important;
  font-size: 1.25em !important;
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}

p {
  margin: 0 0 $small-spacing;
  font-family: 'go-light' !important;
}

a {
  font-family: 'go-light' !important;
  color: material-color('blue', '500');
  text-decoration-skip-ink: auto;
  transition: color $base-duration $base-timing;

  &:focus {
    outline: none;
    outline-offset: none;
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}

[textalign='center'] {
  font-family: 'go-light' !important;
  text-align: center !important;
}
[textalign='left'] {
  font-family: 'go-light' !important;
  text-align: left;
}
[textalign='right'] {
  font-family: 'go-light' !important;
  text-align: right;
}
[textalign='justify'] {
  font-family: 'go-light' !important;
  text-align: justify;
}

svg {
  fill: currentColor;
}

td,
tr,
span,
li,
ul {
  font-family: 'go-light' !important;
}

input,
label {
  font-family: 'go-light' !important;
}
